import { FC } from "react"
import BaseImage from "@trades-org/waas-component-library/dist/components/atoms/base-image/base-image"
import TextGroup from "@trades-org/waas-component-library/dist/components/molecules/text-group/text-group"

export interface CompletedProjectCardProps {
    projectImg: string
    title?: string
    description?: string
}

// https://swiperjs.com/demos/images/nature-1.jpg

export const CompletedProjectCard: FC<CompletedProjectCardProps> = ({ projectImg, title, description }) => {
    return (
        <div className={"flex flex-col"}>
            <BaseImage data-src={projectImg} classNames="swiper-lazy" alt={""} height={350} width={350} img={""} />
            <TextGroup
                wrapperClassNames={"max-w-[350px] py-10 items-center"}
                title={title}
                titleClassNames={"text-sm"}
                description={description}
                descriptionClassNames={"text-xs"}
            />
        </div>
    )
}
