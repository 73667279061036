import TextGroup from "@trades-org/waas-component-library/dist/components/molecules/text-group/text-group"
import { FC } from "react"
import { SectionBusinessInfoProps } from "../../../../types/bright-hive/business-info.types"

const BusinessInfo: FC<SectionBusinessInfoProps> = ({ shortDescription, longDescription, sectionImage, slogan }) => {
    if (!longDescription?.length) return null
    return (
        <div className={"mx-auto max-w-7xl space-y-10 py-10 px-4 sm:px-6 lg:px-14 md:py-16"}>
            <div className={"flex flex-col md:flex-row justify-start"}>
                {sectionImage}
                <div className={"flex flex-col md:block space-y-7 md:pt-14 md:pl-10 lg:pl-24 xl:pl-40"}>
                    <TextGroup
                        wrapperClassNames={"md:max-w-xl pt-5 md:pt-0 space-y-2"}
                        slogan={slogan?.toUpperCase()}
                        sloganClassNames={"text-xs font-extralight"}
                        title={shortDescription}
                        titleClassNames={"text-2xl md:text-4xl text-left leading-relaxed max-w-sm"}
                        description={longDescription}
                        descriptionClassNames={"md:max-w-[420px] text-left text-sm font-light leading-relaxed"}
                    />
                </div>
            </div>
        </div>
    )
}

export default BusinessInfo
