import TextGroup from "@trades-org/waas-component-library/dist/components/molecules/text-group/text-group"
import BaseImage from "@trades-org/waas-component-library/dist/components/atoms/base-image/base-image"
import { FC } from "react"
import { SectionOurProsProps } from "../../../../types/bright-hive/our-pros.types"

const OurPros: FC<SectionOurProsProps> = ({ pros, description, title, fallbackProImageNode }): JSX.Element | null => {
    if (!pros?.length) return null
    return (
        <div className={"bg-[#1D1D1D]"} id={"our-pros"}>
            <div
                className={
                    "flex flex-row justify-center items-center mx-auto max-w-8xl py-20 px-4 sm:px-6 lg:px-14 md:py-16 lg:py-32"
                }>
                <div className={"md:columns-2 gap-32 space-y-10 md:space-y-0"}>
                    <div className={"max-w-[370px] md:pb-20 text-white"}>
                        <TextGroup
                            wrapperClassNames={"space-y-5"}
                            title={title}
                            titleTextType={"h4"}
                            titleClassNames={"text-left"}
                            description={description}
                            descriptionClassNames={"text-left text-sm font-extralight"}
                        />
                    </div>
                    {pros.map(({ name, title, bio, image }, index) => {
                        return (
                            <div
                                key={`team-member-card-${index + 1}`}
                                className={"max-w-[370px] md:pt-24 overflow-hidden"}>
                                {image ? (
                                    <BaseImage
                                        classNames={"rounded-t-3xl"}
                                        img={image}
                                        alt={`team-member-${name}`}
                                        height={290}
                                        width={370}
                                        fit={"object-cover"}
                                    />
                                ) : (
                                    fallbackProImageNode
                                )}
                                <div className={"rounded-b-3xl bg-white py-10 flex justify-center"}>
                                    <TextGroup
                                        wrapperClassNames={"items-center space-y-4 max-w-[240px]"}
                                        slogan={title}
                                        title={name}
                                        titleClassNames={"text-base"}
                                        description={bio}
                                        descriptionClassNames={"text-xs font-light leading-relaxed"}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default OurPros
