import { FC } from "react"
import Header from "../../../components/bright-hive/sections/header"
import Footer from "../../../components/bright-hive/sections/footer"
import HeroAbout from "../../../components/bright-hive/sections/hero-about"
import Qualifications from "../../../components/bright-hive/sections/qualifications"
import BusinessInfo from "../../../components/bright-hive/sections/business-info"
import OurPros from "../../../components/bright-hive/sections/our-pros"
import CompletedProjects from "../../../components/bright-hive/sections/completed-projects"
import { BrightHiveWebsiteProps } from "../../../types/bright-hive/website/website.types"
import TradeCategories from "../../../components/bright-hive/sections/trade-categories"
import HighlightedFeatures from "../../../components/bright-hive/sections/highlighted-features"

export const AboutHelper: FC<BrightHiveWebsiteProps> = ({
    header,
    completedProjects,
    businessInfo,
    highlightedFeatures,
    qualifications,
    ourPros,
    footer,
    heroAbout,
    tradeCategories,
    cNavigate,
    cLocation,
}) => {
    return (
        <>
            <header>
                <Header {...header} navigate={cNavigate} path={cLocation?.pathname} />
            </header>
            <main>
                <HeroAbout {...heroAbout} />
                <Qualifications {...qualifications} />
                <BusinessInfo {...businessInfo} />
                <OurPros {...ourPros} />
                <HighlightedFeatures {...highlightedFeatures} />
                <TradeCategories {...tradeCategories} />
                <CompletedProjects {...completedProjects} />
            </main>
            <footer>
                <Footer {...footer} path={cLocation?.pathname} />
            </footer>
        </>
    )
}

export default AboutHelper
