import TextGroup from "@trades-org/waas-component-library/dist/components/molecules/text-group/text-group"
import { FC } from "react"
import { SectionHeroAboutProps } from "../../../../types/bright-hive/hero-about.types"

const HeroAbout: FC<SectionHeroAboutProps> = ({ title, description, sectionImage }) => {
    return (
        <div className={"mx-auto max-w-7xl space-y-10 py-10 px-4 sm:px-6 lg:px-14 md:py-16"}>
            <div className={"flex flex-col space-y-5 sm:space-y-0 items-center justify-center"}>
                <TextGroup
                    wrapperClassNames={"max-w-[350px] md:max-w-[600px] sm:py-16 sm:items-center space-y-5"}
                    title={title}
                    titleTextType={"h2"}
                    titleClassNames={"text-2xl sm:text-3xl md:text-5xl text-left md:text-center tracking-wide"}
                    description={description}
                    descriptionClassNames={
                        "text-left sm:text-center text-sm max-w-[450px] leading-relaxed font-light tracking-wide"
                    }
                />
                {sectionImage}
            </div>
        </div>
    )
}

export default HeroAbout
