import TextGroup from "@trades-org/waas-component-library/dist/components/molecules/text-group/text-group"
import { StaticImage } from "gatsby-plugin-image"
import BaseCard from "@trades-org/waas-component-library/dist/components/organisms/base-card/base-card"
import { FC } from "react"
import { SectionHighlightedFeaturesProps } from "../../../../types/bright-hive/highlighteed-features.types"

const HighlightedFeatures: FC<SectionHighlightedFeaturesProps> = ({
    features,
    title,
    description,
}): JSX.Element | null => {
    if (!features?.length) return null
    return (
        <div className={"bg-[#005AE7]"} id={"business-features"}>
            <div className={"mx-auto max-w-7xl px-4 sm:px-6 lg:px-14 py-5 md:py-10"}>
                <div className={"space-y-6 md:space-y-16 py-10 lg:mx-20"}>
                    <TextGroup
                        wrapperClassNames={"text-white flex items-center justify-center space-y-5"}
                        title={title}
                        titleClassNames={"text-4xl"}
                        description={description}
                    />
                </div>
                <div className={"justify-items-center sm:grid grid-cols-[minmax(260px,_620px)_minmax(260px,_620px)]"}>
                    {features?.map(({ title, description }, index) => {
                        return (
                            <BaseCard
                                key={`testimonial-${index + 1}`}
                                wrapperClassNames={"flex-row space-x-5 md:max-w-[620px] p-3"}
                                icon={
                                    <div
                                        className={
                                            "h-[45px] min-w-[45px] inline-flex items-center justify-center shadow-md rounded-full bg-white"
                                        }>
                                        <StaticImage
                                            width={18}
                                            height={13}
                                            src={"../../../../images/bright-hive/about/Group 33.svg"}
                                            className={"object-contain text-white"}
                                            alt={"trade-category-placeholder"}
                                        />
                                    </div>
                                }
                                textGroupProps={{
                                    wrapperClassNames: "py-3 max-w-sm xl:max-w-[450px] space-y-3 text-white",
                                    title: title,
                                    description: description,
                                    titleClassNames: "text-left text-lg md:text-base leading-tight",
                                    titleTextType: "h4",
                                    descriptionClassNames:
                                        "text-left text-normal md:text-sm font-light leading-relaxed",
                                }}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default HighlightedFeatures
