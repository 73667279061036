import { graphql, HeadFC, PageProps } from "gatsby"
import AboutHelper from "../helpers/about-helper"
import { aggregate } from "../../../utils/aggregate"
import { propsMap } from "../../../utils/website-dto/bright-hive/props-map"
import { configurationMap } from "../../../utils/website-dto/bright-hive/configuration-map"
import { WebsitePayload } from "../../../utils/website-dto/website-payload"

const AboutPage = ({
    data: {
        api: { website, business, user },
    },
    ...rest
}: PageProps<{
    api: WebsitePayload
}>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const requiredData = aggregate(propsMap, configurationMap, {
        ...website,
        ...business,
        phone: user.phone,
    })
    return <AboutHelper {...requiredData} cNavigate={rest.navigate} />
}
export const Head: HeadFC = () => <title>About Page</title>

export default AboutPage

export const query = graphql`
    query ($businessId: String!, $userId: String!) {
        api {
            website(where: { businessId: $businessId }) {
                ...WebsiteInfo
            }
            business(where: { id: $businessId }) {
                ...BusinessInfo
            }
            user(where: { id: $userId }) {
                ...UserInfo
            }
        }
    }
`
