import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import TextGroup from "@trades-org/waas-component-library/dist/components/molecules/text-group/text-group"
import ArrowLeftIcon from "@heroicons/react/24/solid/ArrowLeftIcon"
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon"
import { FC } from "react"
import { SectionQualificationProps } from "../../../../types/bright-hive/qualifications.types"

const Qualifications: FC<SectionQualificationProps> = ({ qualifications }): JSX.Element | null => {
    if (!qualifications?.length) return null
    return (
        <div className={"bg-[#F3F6F9] relative"}>
            <div
                className={
                    "flex flex-row justify-center items-center mx-auto max-w-8xl py-20 px-4 sm:px-6 lg:px-14 md:py-16"
                }>
                <div id={"qualifications_prev_slide"} className={"hidden md:block mr-20"}>
                    <ArrowLeftIcon
                        width={20}
                        height={20}
                        className={"hover:opacity-90 cursor-pointer text-blue-600 w-6 h-6 lg:w-10 lg:h-10"}
                    />
                </div>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    navigation={{
                        prevEl: "#qualifications_prev_slide",
                        nextEl: "#qualifications_next_slide",
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                        1280: {
                            slidesPerView: 4,
                            spaceBetween: 50,
                        },
                    }}
                    modules={[Navigation]}
                    className="qualification-swiper">
                    {qualifications?.map(({ title, description }, index) => {
                        return (
                            <SwiperSlide className={"bg-transparent"} key={`qualification-card-${index + 1}`}>
                                <TextGroup
                                    wrapperClassNames={"h-auto md:max-w-[200px] space-y-3"}
                                    title={title}
                                    titleTextType={"h5"}
                                    titleClassNames={"text-sm font-medium"}
                                    description={description}
                                    descriptionClassNames={"text-left text-sm font-extralight"}
                                />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                <div id={"qualifications_next_slide"} className={"hidden md:block ml-20"}>
                    <ArrowRightIcon
                        width={20}
                        height={20}
                        className={"hover:opacity-90 cursor-pointer text-blue-600 w-6 h-6 lg:w-10 lg:h-10"}
                    />
                </div>
                <div className={"absolute md:hidden bottom-4 right-7 sm:right-14 z-50"}>
                    <div className={"flex space-x-4"}>
                        <div
                            id={"qualifications_prev_slide"}
                            className={
                                "border-2 border-blue-600 rounded-full p-2 outline-blue-600 hover:outline hover:opacity-90 cursor-pointer"
                            }>
                            <ArrowLeftIcon width={20} height={20} className={"text-blue-600 w-6 h-6 lg:w-10 lg:h-10"} />
                        </div>
                        <div
                            id={"qualifications_next_slide"}
                            className={
                                "border-2 border-blue-600 rounded-full p-2 outline-blue-600 hover:outline hover:opacity-90 cursor-pointer"
                            }>
                            <ArrowRightIcon
                                width={20}
                                height={20}
                                className={"text-blue-600 w-6 h-6 lg:w-10 lg:h-10"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Qualifications
