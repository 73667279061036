import { FC } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Lazy, Navigation } from "swiper"
import { CompletedProjectCard } from "./components"

import "swiper/css"
import "swiper/css/lazy"
import "swiper/css/navigation"
import ArrowLeftIcon from "@heroicons/react/24/solid/ArrowLeftIcon"
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon"
import { SectionCompletedProjectsProps } from "../../../../types/bright-hive/completed-projects.types"
import { CompletedProjectImage } from "../../../../types/bright-hive/common/completed-project-image.types"
import Typography from "@trades-org/waas-component-library/dist/components/atoms/typography/typography"

const CompletedProjects: FC<SectionCompletedProjectsProps> = ({ completedProjectsData, title }): JSX.Element | null => {
    const allCompletedProjectImages = completedProjectsData?.reduce<CompletedProjectImage[]>(
        (accumulator, { completedProjectImages }) => accumulator.concat(completedProjectImages),
        []
    )
    if (!allCompletedProjectImages?.length) return null
    return (
        <div className={"mx-auto max-w-7xl px-4 sm:px-6 lg:px-14"}>
            <Typography textType={"h2"}>{title}</Typography>
            <div
                className={
                    "relative space-y-10 md:space-y-0 md:flex md:flex-row md:items-center md:justify-between pt-10 pb-10 "
                }>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    lazy={true}
                    navigation={{
                        prevEl: "#completed_projects_prev_slide",
                        nextEl: "#completed_projects_next_slide",
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                    }}
                    modules={[Lazy, Navigation]}
                    className="completed-projects-swiper">
                    {allCompletedProjectImages.map(({ url, description, caption }, index) => {
                        return (
                            <SwiperSlide key={`completed-project-card-${index + 1}`} className={"bg-transparent"}>
                                <CompletedProjectCard projectImg={url} title={caption} description={description} />
                                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                <div className={"absolute bottom-5 right-10 md:right-14 z-50"}>
                    <div className={"flex space-x-4"}>
                        <div
                            id={"completed_projects_prev_slide"}
                            className={
                                "border-2 border-blue-600 rounded-full p-2 outline-blue-600 hover:outline hover:opacity-90 cursor-pointer"
                            }>
                            <ArrowLeftIcon width={20} height={20} className={"text-blue-600 w-6 h-6 lg:w-7 lg:h-7"} />
                        </div>
                        <div
                            id={"completed_projects_next_slide"}
                            className={
                                "border-2 border-blue-600 rounded-full p-2 outline-blue-600 hover:outline hover:opacity-90 cursor-pointer"
                            }>
                            <ArrowRightIcon width={20} height={20} className={"text-blue-600 w-6 h-6 lg:w-7 lg:h-7"} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompletedProjects
